<template>
  <div>
    <footer>
      <div class="md:container lg:container xl:container mx-auto">
        <div
          class="gap-3 my-5 py-5 px-2 flex flex-row justify-between flex-wrap xs:flex-col sm:flex-col md:flex-col xs:divide-y sm:divide-y md:divide-y"
        >
          <div class="sm:text-center md:text-center py-10">
            <!-- csak SSR esetén !!-->
            <!-- <Nuxt Img
              src="bestsellers_logo_smaller_v2.svg"
              class="w-auto h-10 inline-block mr-1 p-1"
              alt="Bestsellers"
              title="Bestsellers"
              width="auto"
              height="40"
            /> -->
            <img
              src="/bestsellers_logo_smaller_v2.svg"
              class="w-auto h-10 inline-block mr-1 p-1"
              alt="Bestsellers"
              title="Bestsellers"
              width="auto"
              height="40"
            />
            <p class="mt-4">
              <span class="text-2xl mx-2"><font-awesome-icon :icon="['fas', 'quote-right']" /></span>
              {{ $t('Through Books to Knowledge and Dreams') }}
            </p>
            <div class="w-auto mt-10">
              <img
                src="/logo/barion-card-strip-intl.svg"
                class="w-auto h-9 inline-block mr-1 bg-boxbg-light p-1 rounded-md"
                alt="Barion"
                title="Barion"
                width="auto"
                height="36"
              />
            </div>
            <div class="w-auto mt-3">
              <img 
                src="/logo/gls.svg"
                class="w-auto h-6 inline-block mr-1 bg-boxbg-light p-1 rounded-md"
                alt="GLS"
                title="GLS"
                width="auto"
                height="24"
              />
              <img 
                src="/logo/PayPal.svg"
                class="w-auto h-6 inline-block mr-1 bg-boxbg-light p-1 rounded-md"
                alt="Paypal"
                title="Paypal"
                width="auto"
                height="24"
              />
              <!--
              <img 
                src="/logo/mastercard.png"
                class="w-auto h-6 inline-block mr-1 bg-boxbg-light p-1 rounded-md"
              />
              <img 
                src="/logo/maestro.png"
                class="w-auto h-6 inline-block mr-1 bg-boxbg-light p-1 rounded-md"
              />
              <img 
                src="/logo/american-express.png"
                class="w-auto h-6 inline-block mr-1 bg-boxbg-light p-1 rounded-md"
              />
              <img 
                src="/logo/visa.png"
                class="w-auto h-6 inline-block mr-1 bg-boxbg-light p-1 rounded-md"
              />
              -->
            </div>
          </div>
          <div class="py-10">
            <h2 class="text-xl">
              {{ $t('Customer Services') }}
            </h2>
            <ul class="">
              <li class="text-primary ml-3 my-2 font-sans text-sm">
                <NuxtLink
                  :to="localePath('purchase-and-shipping-conditions')"
                  class="hover:underline"
                >
                  {{ $t('Purchase and shipping conditions') }}
                </NuxtLink>
              </li>
              <li class="text-primary ml-3 my-2 font-sans text-sm">
                <NuxtLink
                  :to="localePath('privacy-policy')"
                  class="hover:underline"
                >
                  {{ $t('Privacy Policy') }}
                </NuxtLink>
              </li>
              <li class="text-primary ml-3 my-2 font-sans text-sm">
                <NuxtLink
                  :to="localePath('terms-and-conditions')"
                  class="hover:underline"
                >
                  {{ $t('Terms and Conditions') }}
                </NuxtLink>
              </li>
              <li class="text-primary ml-3 my-2 font-sans text-sm">
                <NuxtLink
                  :to="localePath('cookie')"
                  class="hover:underline"
                >
                  {{
                    $t('Cookie information')
                  }}
                </NuxtLink>
              </li>
            </ul>
          </div>
          <div class="py-10">
            <h2 class="text-xl">
              Bestsellers
            </h2>
            <ul>
              <li class="text-primary ml-3 my-2 font-sans text-sm">
                <NuxtLink
                  :to="localePath('about-us')"
                  class="hover:underline"
                >
                  {{ $t('About us') }}
                </NuxtLink>
              </li>
              <li class="text-primary ml-3 my-2 font-sans text-sm">
                <NuxtLink
                  :to="localePath('contact-us')"
                  class="hover:underline"
                >
                  {{ $t('Shop') }}: 1051 Budapest, Október 6. utca
                  11.
                </NuxtLink>
              </li>
              <li class="text-primary ml-3 my-2 font-sans text-sm">
                <NuxtLink
                  :to="localePath('contact-us')"
                  class="hover:underline"
                >
                  {{ $t('Phone') }}: +361-312-1295
                </NuxtLink>
              </li>
              <li class="text-primary ml-3 my-2 font-sans text-sm">
                <NuxtLink
                  :to="localePath('contact-us')"
                  class="hover:underline"
                >
                  bestsellers@bestsellers.hu
                </NuxtLink>
              </li>
              <li class="text-primary ml-3 my-2 text-3xl">
                <a
                  href="https://www.facebook.com/BestsellersBudapest"
                  target="_blank"
                  aria-label="Go to our Facebook page"
                  class="m-1"
                ><font-awesome-icon :icon="['fab', 'facebook-square']" /></a>
                <a
                  href="https://www.instagram.com/bestsellersbookshop/"
                  target="_blank"
                  aria-label="Go to our Instagram page"
                  class="m-1"
                ><font-awesome-icon :icon="['fab', 'instagram-square']" /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
    <div class="pb-3 text-secondary mx-auto mb-5 text-center text-xs">
      © Bestsellers {{ new Date().getFullYear() }} All rights reserved.<br/>
      <span style="font-size: 7px;" class="font-sans">build 202503251300</span>
    </div>
  </div>
</template>

<script setup>
  const localePath = useLocalePath()
</script>
